.rmsc .item-renderer.disabled{
    opacity:1;
}

.rmsc .item-renderer input:disabled{
    visibility:hidden;
    margin: 0 -15px 0 0;
}

.react-datepicker{
    /* width: 100%; */
}