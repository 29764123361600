
.siteHistory{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 11px;
    margin-top: 0.4rem;
    margin-bottom: 3rem;
} 
.partitions{
    list-style: none;
}

.partitions :first-child{
    margin-top: 5px;
}