.showone > thead > th{
    border: 2px solid black !important;
}

.showone > tbody > tr >  td{
    border: 2px solid black !important;
}

.showone > tr > th{
    border: 2px solid black !important;
}

.showone > tr > td{
    border: 2px solid black !important;
}


.showonetrorg{
    background-color: #f16722 !important;
}





.ran_cbn_riot{
    height: 10px !important;
}

