
table, th, td {
   /* border: 2px solid black; */
   border-radius: 2px solid black;
   border-bottom: "2px solid #24292D",
   

}



#flush-collapseTwo,#flush-collapseThree,#flush-collapsefour{
  overflow-y:scroll;
height:200px;
}::-webkit-scrollbar {
  width: 1px;
  height: 8px;
  padding: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #143b64; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#ff5500; 
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgba(255, 255, 255, 0);  */
}
.accordion .card-header:after {
  font-family: 'FontAwesome';  
  content: "\f068";
  float: right; 
}
.accordion .card-header.collapsed:after {
   
  content: "\f067"; 
  /* background: rgb(90, 17, 17);  */
}


