/* h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
  }
   */
   .form {
    /* background: #0e101c; */
    min-width: 320px;
    max-width: 400px;
    /* margin: 4.5em auto; */
  }
  
  .error {
    color: #bf1650;
  }

  .error_custom {
    margin-top: -25px;
    margin-bottom: 20px;
  }
  
  .error::before {
    display: inline;
    content: "⚠ ";
  }
  
  input ,select{
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border-bottom: 2px solid var(--purple);
    padding: 4px 6px;
    margin-bottom: 6px;
    font-size: 12px;
    outline:none
  }
  
  label {
    /* line-height: 2; */
    text-align: left;
    display: block;
    margin-bottom: 6px;
    margin-top: 10px;
    color: black;
    font-size: 13px;
    font-weight: 600;
  }
  
  input[type="submit"] {
    background: #13B497;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 20px;
    padding: 10px;
    font-size: 10px;
    font-weight: 100;
    letter-spacing: 10px;
  }
  
  input[type="submit"]:hover {
    background: #097561;
  }
  
  input[type="button"]:active,
  input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
  input:disabled {
    opacity: 0.4;
  }
  
  input[type="button"]:hover {
    transition: 0.3s all;
  }
  
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }
  
  