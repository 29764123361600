button.tooltiplink {
  position: relative;
}

/* owner details start */

.owner-details {
  width: fit-content;
  height: fit-content;
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border: 1px solid gray;
  z-index: 999;
}

.postCheckOngoing{
  background-color: #6fd1ca;
}
button.tooltiplink:hover::after {
  content: attr(data-title);
  background-color: #d67a12;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: wrap;
  z-index: 1;
}



[data-headerbg="color_2"] .header-left input {
  border-color: #1d548f;
  background-color: white !important;
  color: #fff;
}

#color-of-datepicker {
  border-color: #1d548f;
  background-color: white !important;
  color: #252323;
}

.react-datepicker-wrapper {
  display: inline-block;
  /* padding: 4px 6px !important; */
  border: 0;
  
 
}
.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 15vh;
}

/* .tss-vbwf5t-MUIDataTableBodyCell-root,
.tss-1akey0g-MUIDataTableHeadCell-data,
.css-11mo0d2-MuiTableCell-root {
  font-size: 10px !important;
  padding:4px  !important;

} */


/* .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.tss-52j81d-MUIDataTableSearch-clearIcon.css-78trlr-MuiButtonBase-root-MuiIconButton-root{
  display: none !important;
} */

/* .tss-1akey0g-MUIDataTableHeadCell-data{
    padding-left: 1.5rem !important;
} */
.tss-1akey0g-MUIDataTableHeadCell-data{
  padding-left: 1.5rem !important;
}
.tss-1acbflw-MUIDataTable-responsiveScrollMaxHeight {
 height: 100%;
}
.css-el75qc-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 0.5rem;
  vertical-align: inherit;
  padding: 4px !important;
  color: rgba(0, 0, 0, 0.87);
  max-height: fit-content;
  font-size: 10px !important;
  margin: auto !important;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 4;
}

.css-11mo0d2-MuiTableCell-root {
  font-size: 10px !important;
  padding: 4px !important;

}
.css-1w1rijm-MuiButtonBase-root-MuiButton-root {
  padding: 0 !important;
  
}

table > button {
  padding: 0.5rem !important;
}

.tss-qbo1l6-MUIDataTableToolbar-actions {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  /* flex: 1 1 auto; */
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  row-gap: 0.6rem;
}
/* Loader css */
.loader {
  color:var(--blue);
  position: relative;
  display: inline-block;
  margin-top: 40px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  letter-spacing: 4px;
  box-sizing: border-box;
}
.loader::before {
  content: '';  
  position: absolute;
  right: 70px;
  bottom: 10px;
  height: 28px;
  width: 5.15px;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader1 1s linear infinite alternate;
}
.loader::after {
  content: '';  
  width: 10px;
  height: 10px;
  position: absolute;
  left: 125px;
  top: 2px;
  border-radius: 50%;
  background: red;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    transform: translate(0px, 0px) scaleX(1);
  }
  14% {
    transform: translate(-12px, -16px) scaleX(1.05);
  }
  28% {
    transform: translate(-27px, -28px) scaleX(1.07);
  }
  42% {
    transform: translate(-46px, -35px) scaleX(1.1);
  }
  57% {
    transform: translate(-70px, -37px) scaleX(1.1);
  }
  71% {
    transform: translate(-94px, -32px) scaleX(1.07);
  }
  85% {
    transform: translate(-111px, -22px) scaleX(1.05);
  }
  100% {
    transform: translate(-125px, -9px) scaleX(1);
  }
}

@keyframes animloader1 {
  0% {
    box-shadow: 0 -6px, -122.9px -8px;
  }
  25%, 75% {
    box-shadow: 0 0px, -122.9px -8px;
  }
  100% {
    box-shadow: 0 0px, -122.9px -16px;
  }
} 
/* loader css closed */
.card {
  width: 100% !important;
}

input.form-control,
select.form-control,
select.form-group,
textarea.form-control,
input.form-group,
.dropdown-heading {
  border-radius: 2px !important;
  min-height: 40px !important;
  max-height: 40px !important;
  min-width: 100% !important;
  max-width: 115px !important;
  /* padding: 0.3rem !important; */
  font-size: 14px !important;
  border: 1px solid rgb(151 151 151) !important;
  
  /* color: rgb(159, 156, 156) !important; */
  color: rgb(0, 0, 0) !important;
  
}


.rmsc .gray {
  color: #000;
}

.dropdown-heading-value {
  font-size: 12px !important;
}

.dropdown-heading {
  padding: 0 5px 0 5px !important;
  max-height: 30px !important;
}

.form-group {
  margin: 0 !important;
  padding: 6px !important;
  font-size: 14px !important;

}

.react-datepicker__input-container input.form-control {
  width: 100% !important;
}



.css-19m8t6j-MuiTableCell-root {
  padding: -10px !important;
}

.css-1ygcj2i-MuiTableCell-root{
  padding: -10px !important;
 
}
.deznav {
  max-width: 17.563rem !important;
  /* height: 100vh !important;
  overflow-y: scroll; */
}

.deznav i {
  font-size: 12px !important;
}

.deznav span , .deznav a {
  font-size: 11px !important;
}


.tss-vbwf5t-MUIDataTableBodyCell-root,
.tss-1akey0g-MUIDataTableHeadCell-data,
.css-11mo0d2-MuiTableCell-root {
  font-size: 11px !important;
  padding: 1px !important;

min-width: 10px;


}


.container-fluid{
  padding-top: 15px !important;
  padding-right: 10px !important;
  padding-left: 1px !important;
}

input[type="checkbox"]:after {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-left: -1px;
  border: 1px solid #143b64;
  border-radius: 3px;
  background: #d4d7da;
  /* margin:0px 2.4rem; */
  /* line-height: 1.3;  */
}
/* #siteId:after{
  margin:0px 1.5rem !important 
} */
.css-luvyva-MuiTableCell-root{
  padding: 8px;
}

/* 
.css-8ff7ud-MuiTableCell-root{
  padding: 0 !important;
} */

.sla-report-change{
  margin-top: -2rem;
}




.rmsc .dropdown-container {
  border: none !important;
  width: 100% ;
}

.react-datepicker-wrapper{
  padding: 0px !important;
}

.form-group label{
  color : rgb(80, 80, 80);
  margin-top: 6px;
}

/* input:disabled{
  opacity: 1;s
} */

.weather::-webkit-scrollbar{
  width: 0px !important;
  height: 0px !important;
  display: none !important;
}
.loginAwaitedNoShow,.inProgressHold{
  background-color: #FFC000;
}
.Scheduled{
  background-color: #FFFF00;
}
.inProgress{
  background-color: #00B0F0;
}
.loginAwaited{
  background-color: #FF0000;
}
.Completed{
  background-color: #00B050;
}

.MUIDataTable{
  height: 100%;
}