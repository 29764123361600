.apexcharts-legend-series {
    display:flex !important;
    padding: 6px 4px ;
  }

.apexcharts-legend{
    right: -50px !important;
}

.apexcharts-legend-marker{
    left:0px  !important;
}