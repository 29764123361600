.showAccordian{
    display: none;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hideAccordian{
    display: block;
}
.dropButton{
    border:none;
    background-color:transparent;
    width: 100%;
    margin-left:0px ;
    padding-left: 0px;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    /* border: 1px solid #ccc;
    display: inline-block;*/
    padding: 6px 5px; 
    cursor: pointer;
    margin-right: 5px;
}
.fileSection{
    display: flex;
    justify-content: space-between;
    flex-wrap:  wrap;
}

