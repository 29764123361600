/* .react-datepicker__input-container {
    width: 201%;
} */

td{
    padding: 0px 5px;
}

/* .react-datepicker{
    width:328px !important;
} */


.maptabletd{
    color:black;
}

.maptabletr{
    height:25px !important;
}

.cbn_logs > td{
    width:30% !important;
}

.cbn_logs > th{
    width:30% !important;
}
