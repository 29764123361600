.react-datepicker-wrapper {
    padding: 0px !important;
}

/* .twoDate{
    max-width: ;
} */

.react-datepicker {
    /* width: 100%; */
}

.row-custom {
    padding: 0px;
    margin: 0px !important;
}

.card-body-custom {
    padding: 0.5rem !important;
}


.one-line-col1 {
    min-width: 28% !important;
    max-width: 28% !important;
}

.one-line-col2 {
    min-width: 18% !important;
    max-width: 18% !important;
}

.one-line-col3 {
    min-width: 18% !important;
    max-width: 18% !important;
}

.one-line-col4 {
    min-width: 18% !important;
    max-width: 18% !important;
}

.one-line-col5 {
    min-width: 18% !important;
    max-width: 18% !important;
}

@media only screen and (min-width: 615px) {
    .boxcol {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

input,
select {
    border: 2px solid var(--gray);
    border-radius: 1px;
}

label {
    margin: 0px 8px;
    margin-bottom: 0px !important;
}

.react-datepicker-year-header {
    font-size: 12px;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    content: "Hello";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #be0404;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "N";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #33f321;
}


input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    content: "Y";
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}